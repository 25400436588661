<template>
  <AvatarBadgeStyled :size="size" :color="countAvatarColor" :fontSize="fontSize"> +{{ count }} </AvatarBadgeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const AvatarBadgeStyled = styled('div')`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  font-size: ${({ fontSize }) => fontSize};
  color: #fff;
  line-height: ${({ size }) => size};
  text-align: center;
  background: ${({ color }) => color};
  text-transform: uppercase;
  position: relative;
  &.select {
    border: 1px solid ${({ theme }) => theme.colors.atomic.textMain};
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors.white};
  }
  &.edit {
    border: 1px solid ${({ theme }) => theme.colors.atomic.chart.red};
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors.white};
  }
  .icon {
    fill: ${({ theme }) => theme.colors.atomic.artylickOrange};
    color: ${({ theme }) => theme.colors.atomic.artylickOrange};
    width: 25%;
    height: auto;
    position: absolute;
    left: 15%;
    top: -10%;
  }
`
export default {
  inject: ['theme'],
  props: {
    size: {
      type: String,
      require: false,
      default: '25px',
    },
    fontSize: {
      type: String,
      require: false,
      default: '12px',
    },
    count: {
      type: Number,
      require: true,
    },
  },
  components: {
    AvatarBadgeStyled,
  },
  computed: {
    countAvatarColor() {
      const color = 'grey'
      return this.theme.colors.atomic.avatar[color]
    },
  },
}
</script>
