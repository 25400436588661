<template>
  <WidgetErrorStyled>
    <i18n path="messages.errorLoadingWidgetType" v-if="widget && widget.type">
      <template v-slot:type>
        {{ widget.type }}
      </template>
    </i18n>
    <template>
      {{ $t('messages.errorLoadingWidget') }}
    </template>
  </WidgetErrorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const WidgetErrorStyled = styled('div')``

export default {
  props: {
    widget: {
      type: Object,
    },
  },
  components: {
    WidgetErrorStyled,
  },
}
</script>
