var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardFooterStyled', {
    class: {
      scrolled: !_vm.hasScrolled
    },
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_c('DashboardToolbox', {
    attrs: {
      "isMobile": _vm.isMobile,
      "editPermission": _vm.canEditDashboard,
      "sharePermission": _vm.canShareDashboard,
      "dashboard": _vm.dashboard
    },
    on: {
      "editModeToggle": _vm.editMode
    }
  }), _vm.$apollo.loading ? _c('LoadingDots') : _vm._e(), _vm.dashboardUsers && _vm.dashboardUsers.length > 1 && !_vm.$apollo.loading ? _c('DashboardUserWrapperStyled', [_vm._l(_vm.mappedUserData, function (data) {
    return _c('AvatarWithSimplePopOver', {
      key: data.id,
      attrs: {
        "size": '30px',
        "fontSize": '14px',
        "userData": data,
        "isOwner": data.isOwner,
        "count": _vm.mappedUserData.length
      }
    });
  }), _vm.userCount > 0 ? _c('AvatarCountBadgeWithModal', {
    attrs: {
      "size": '30px',
      "fontSize": '14px',
      "count": _vm.userCount,
      "userData": _vm.dashboardUsers,
      "placement": 'right',
      "modalDirection": 'top'
    }
  }) : _vm._e()], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }