var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WrapperStyled', [_c('SimplePopover', {
    attrs: {
      "isShort": true,
      "popoverClass": 'avatarPopOver',
      "popoverContent": _vm.userEmail,
      "popoverTitle": _vm.fullName ? _vm.fullName : _vm.userName,
      "placement": _vm.placement,
      "trigger": _vm.trigger,
      "id": "sensitive"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('AvatarBadge', {
          attrs: {
            "size": _vm.size,
            "color": _vm.avatarColor,
            "fontSize": _vm.fontSize,
            "isEditing": _vm.isEditing,
            "isSelected": _vm.isSelected,
            "isOwner": _vm.isOwner,
            "userData": _vm.userData
          }
        }), _vm.isOwner && _vm.count > 1 ? _c('div', {
          staticClass: "divider"
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }