var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ToolsWrapperStyled', {
    attrs: {
      "isMobile": _vm.isMobile
    }
  }, [_c('ToolsButtonStyled', {
    on: {
      "click": _vm.toggleToolsMenu
    }
  }, [_c('TalpaIcon', {
    staticClass: "icon",
    attrs: {
      "scope": 'Misc',
      "name": 'MenuIcon'
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$tc('dashboard.tools.tool', 2)))])], 1), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.isToolsExpanded ? _c('ToolsMenuStyled', {
    attrs: {
      "isMobile": _vm.isMobile
    }
  }, [_c('IconWrapperStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('add'),
      expression: "tooltip('add')"
    }],
    on: {
      "click": _vm.addWidget
    }
  }, [_c('PlusIcon')], 1), _c('IconWrapperStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('edit'),
      expression: "tooltip('edit')"
    }],
    class: {
      activated: _vm.isEditMode
    },
    attrs: {
      "disabled": !_vm.editPermission
    },
    on: {
      "click": _vm.toggleEditMode
    }
  }, [_c('EditIcon', {
    staticClass: "icon"
  })], 1), _c('div', {
    staticClass: "divider"
  }), _c('IconWrapperStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('share'),
      expression: "tooltip('share')"
    }],
    attrs: {
      "disabled": !_vm.sharePermission
    },
    on: {
      "click": _vm.shareDashboard
    }
  }, [_c('Share2Icon')], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }