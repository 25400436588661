var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('DashboardStyled', {
    attrs: {
      "picture": _vm.dashboardBackground,
      "showInitialAdd": _vm.showInitialAdd
    }
  }, [_vm.showInitialAdd ? _c('InitialAddStyled', [_c('div', [_vm._v(_vm._s(_vm.$t('messages.emptyDashboard')))]), _vm.canAddWidget ? _c('div', [_vm._v(_vm._s(_vm.$t('messages.emptyDashboardAdd')))]) : _vm._e()]) : _c('MainStyled', [_c('Subheader', {
    attrs: {
      "hasScrolled": _vm.hasScrolled
    }
  }), _vm.isLoading ? _c('TalpaLoaderWrapper') : _c('GridLayout', {
    attrs: {
      "layout": _vm.layout,
      "col-num": 12,
      "row-height": 3 * 16,
      "is-draggable": true,
      "is-resizable": true,
      "is-mirrored": false,
      "margin": [8, 8],
      "use-css-transforms": true,
      "responsive": true,
      "autoSize": true,
      "verticalCompact": true,
      "breakpoints": _vm.breakpoints,
      "cols": _vm.breakpointCols
    },
    on: {
      "layout-updated": _vm.layoutUpdated
    }
  }, _vm._l(_vm.layout, function (gridItem) {
    return _c('GridItem', {
      key: gridItem.id,
      attrs: {
        "x": gridItem.x,
        "y": gridItem.y,
        "w": gridItem.w,
        "h": gridItem.h,
        "i": gridItem.i,
        "is-draggable": _vm.isEditMode,
        "is-resizable": _vm.isEditMode,
        "dragAllowFrom": '.widget-header'
      },
      on: {
        "resize": function resize($event) {
          return _vm.gridItemResize(gridItem.id, $event);
        },
        "move": function move($event) {
          return _vm.gridItemMove(gridItem.id, $event);
        },
        "resized": function resized($event) {
          return _vm.gridItemResized(gridItem.id, $event);
        },
        "moved": function moved($event) {
          return _vm.gridItemMoved(gridItem.id, $event);
        }
      }
    }, [_c('Widget', {
      ref: gridItem.id,
      refInFor: true,
      attrs: {
        "isEditMode": _vm.isEditMode,
        "widget": gridItem.widget,
        "gridItemID": gridItem.id,
        "dashboardType": _vm.dashboard.type
      },
      on: {
        "showWidgetSettings": function showWidgetSettings($event) {
          return _vm.showWidgetSettings(gridItem);
        },
        "deleteGridItem": function deleteGridItem($event) {
          return _vm.deleteGridItem(gridItem);
        },
        "updateTitle": function updateTitle($event) {
          return _vm.updateWidgetTitle($event, gridItem.id);
        }
      }
    })], 1);
  }), 1)], 1), _vm.canAddWidget ? _c('DashboardFooter', {
    staticClass: "footer",
    attrs: {
      "hasScrolled": _vm.hasScrolled,
      "dashboard": _vm.dashboard,
      "isOwner": _vm.isOwner,
      "isArchonorTalpaProduct": _vm.isArchonorTalpaProduct,
      "userId": _vm.userId
    },
    on: {
      "editModeToggle": _vm.editMode
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }