<template>
  <ToolsWrapperStyled :isMobile="isMobile">
    <ToolsButtonStyled @click="toggleToolsMenu">
      <TalpaIcon class="icon" :scope="'Misc'" :name="'MenuIcon'" />
      <span>{{ $tc('dashboard.tools.tool', 2) }}</span>
    </ToolsButtonStyled>
    <transition name="slide-fade">
      <ToolsMenuStyled v-if="isToolsExpanded" :isMobile="isMobile">
        <IconWrapperStyled @click="addWidget" v-tooltip="tooltip('add')">
          <PlusIcon />
        </IconWrapperStyled>
        <IconWrapperStyled
          :disabled="!editPermission"
          @click="toggleEditMode"
          v-tooltip="tooltip('edit')"
          :class="{ activated: isEditMode }"
        >
          <EditIcon class="icon" />
        </IconWrapperStyled>
        <div class="divider" />
        <IconWrapperStyled :disabled="!sharePermission" @click="shareDashboard" v-tooltip="tooltip('share')">
          <Share2Icon />
        </IconWrapperStyled>
      </ToolsMenuStyled>
    </transition>
  </ToolsWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { TalpaIcon } from '@common/components'
import { PlusIcon, EditIcon, Share2Icon } from 'vue-feather-icons'
import { Base } from '@styles/buttons'
import { VTooltip } from 'v-tooltip'

const ToolsWrapperStyled = styled('div')`
  display: flex;
  ${({ isMobile }) => isMobile && 'flex-direction:column-reverse'};
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.3, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(-10px);
    ${({ isMobile }) => isMobile && 'transform: translateY(10px)'};
    opacity: 0;
  }
`
const ToolsButtonStyled = styled(Base)`
  min-width: 90px;
  height: 46px;
  background: ${({ theme }) => theme.colors.solidBG};
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.16).css())};
  justify-content: space-around;
  padding: 0.25rem 0.5rem;
  > span {
    text-transform: uppercase;
    margin-left: 0.5rem;
  }
  .icon,
  .icon svg {
    width: 16px !important;
    height: 16px !important;
  }
  &:hover {
    color: ${props => props.theme.colors.primaryActive};
  }
  &:active {
    transform: translateY(1px);
  }
`
const ToolsMenuStyled = styled('div')`
  display: flex;
  align-items: center;
  width: 145px;
  height: 42px;
  background: ${({ theme }) => theme.colors.atomic.toolsMenuBG};
  margin-left: 1rem;
  ${({ isMobile }) => isMobile && 'margin-left: 0; margin-bottom: 0.5rem; position:absolute; bottom:3.5rem;'};
  border-radius: 8px;
  justify-content: space-evenly;
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 3px 6px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.16).css())};
  padding: 0 0.2rem;
  .divider {
    display: flex;
    margin: 0.5rem;
    width: 1px;
    height: 25px;
    background: ${({ theme }) => theme.colors.atomic.divider};
  }
`
const IconWrapperStyled = styled(Base)`
  padding: 0.3rem;
  border-radius: 8px;
  .icon {
    padding: 0 0 0.1rem 0.1rem;
  }
  .feather {
    cursor: pointer;
    width: 16px !important;
    height: 16px !important;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primaryActive};
    background: ${({ theme }) => theme.colors.atomic.toolMenuHover};
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    color: ${({ theme }) => chroma(theme.colors.white).alpha(0.3).css()};
    background: ${({ theme }) => theme.colors.talpaGrey};
  }
  &.activated {
    color: ${({ theme }) => theme.colors.primaryActive};
    background: ${({ theme }) => theme.colors.atomic.toolMenuHover};
  }
`
export default {
  directives: {
    tooltip: VTooltip,
  },
  props: {
    editPermission: {
      type: Boolean,
      required: true,
      default: false,
    },
    sharePermission: {
      type: Boolean,
      required: true,
      default: false,
    },
    dashboard: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    TalpaIcon,
    PlusIcon,
    EditIcon,
    Share2Icon,
    ToolsButtonStyled,
    ToolsMenuStyled,
    ToolsWrapperStyled,
    IconWrapperStyled,
  },
  data() {
    return {
      isToolsExpanded: false,
      isEditMode: false,
    }
  },
  methods: {
    addWidget() {
      this.$root.$emit('activateOverlay', 'WidgetSettingsOverlay', {
        dashboard: this.dashboard,
        mode: 'add',
      })
    },
    tooltip(mode) {
      return {
        content: this.toolTipContent(mode),
        placement: 'top',
        boundariesElement: '#app',
        classes: 'dashboard',
        trigger: 'hover',
      }
    },
    toolTipContent(mode) {
      if (mode === 'add') {
        return this.$tc('dashboard.tools.addWidget')
      } else if (mode === 'edit') {
        return this.$tc('dashboard.tools.editWidget')
      } else if (mode === 'share') {
        return this.$tc('dashboard.tools.shareDashboard')
      }
    },
    toggleToolsMenu() {
      this.isToolsExpanded = !this.isToolsExpanded
    },
    shareDashboard() {
      this.$root.$emit('activateOverlay', 'DashboardShareOverlay', this.dashboard)
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode
      this.$emit('editModeToggle', this.isEditMode)
    },
  },
}
</script>
