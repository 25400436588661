<template>
  <WrapperStyled>
    <SimplePopover
      :isShort="true"
      :popoverClass="'avatarPopOver'"
      :popoverContent="userEmail"
      :popoverTitle="fullName ? fullName : userName"
      :placement="placement"
      :trigger="trigger"
      id="sensitive"
    >
      <template v-slot:trigger>
        <AvatarBadge
          :size="size"
          :color="avatarColor"
          :fontSize="fontSize"
          :isEditing="isEditing"
          :isSelected="isSelected"
          :isOwner="isOwner"
          :userData="userData"
        />
        <div v-if="isOwner && count > 1" class="divider" />
      </template>
    </SimplePopover>
  </WrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import SimplePopover from '@common/components/src/SimplePopover'
import AvatarBadge from '@/components/Atomic/Atoms/AvatarBadge'

const WrapperStyled = styled('div')`
  cursor: pointer;
  display: flex;
    .divider {
      width: 1px;
      background: ${({ theme }) => theme.colors.atomic.divider};
      height: 30px;
      margin-left: 0.5rem;
    }
  }
`
export default {
  inject: ['theme'],
  props: {
    size: {
      type: String,
      require: false,
      default: '25px',
    },
    userData: {
      type: Object,
      require: false,
    },
    fontSize: {
      type: String,
      require: false,
      default: '12px',
    },
    isEditing: {
      type: Boolean,
      require: false,
      default: false,
    },
    isSelected: {
      type: Boolean,
      require: false,
      default: false,
    },
    isOwner: {
      type: Boolean,
      require: false,
      default: false,
    },
    placement: {
      type: String,
      required: false,
      default: 'top-end',
    },
    trigger: {
      type: String,
      required: false,
      default: 'hover',
    },
    count: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  components: {
    AvatarBadge,
    SimplePopover,
    WrapperStyled,
  },
  computed: {
    userEmail() {
      return get(this.userData, 'email', '')
    },
    fullName() {
      const firstName = get(this.userData, 'firstName', null)
      const lastName = get(this.userData, 'lastName', null)
      return firstName && lastName ? firstName + ' ' + lastName : null
    },
    userName() {
      return get(this.userData, 'name', '')
    },
    avatarName() {
      if (this.fullName) {
        return this.fullName
          .split(' ')
          .slice(0, 2)
          .map(s => s.charAt(0).toUpperCase())
          .join('')
      } else {
        return this.userName.substring(0, 2)
      }
    },
    avatarColor() {
      const color = get(this.userData, 'uiSettings.avatarColor', '')
      return this.theme.colors.atomic.avatar[color]
    },
  },
}
</script>
