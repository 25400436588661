var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SingleAssetSelectorStyled', [_c('ButtonStyled', {
    attrs: {
      "isLeft": true,
      "disabled": _vm.prevDisabled
    },
    on: {
      "click": _vm.prevAsset
    }
  }, [_c('ChevronLeftIcon')], 1), _c('Multiselect', {
    staticClass: "semi-darker",
    attrs: {
      "value": _vm.singleSelectedAsset,
      "multiple": false,
      "options": _vm.dashboardAssets,
      "showLabels": false,
      "placeholder": _vm.$tc('asset', 2),
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.updateSingleSelectedAsset
    }
  }), _c('ButtonStyled', {
    attrs: {
      "disabled": _vm.nextDisabled
    },
    on: {
      "click": _vm.nextAsset
    }
  }, [_c('ChevronRightIcon')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }