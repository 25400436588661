<template>
  <DashboardFooterStyled :class="{ scrolled: !hasScrolled }" :isMenuExpanded="isMenuExpanded">
    <DashboardToolbox
      :isMobile="isMobile"
      :editPermission="canEditDashboard"
      :sharePermission="canShareDashboard"
      @editModeToggle="editMode"
      :dashboard="dashboard"
    />
    <LoadingDots v-if="$apollo.loading" />
    <DashboardUserWrapperStyled v-if="dashboardUsers && dashboardUsers.length > 1 && !$apollo.loading">
      <AvatarWithSimplePopOver
        :size="'30px'"
        :fontSize="'14px'"
        v-for="data in mappedUserData"
        :key="data.id"
        :userData="data"
        :isOwner="data.isOwner"
        :count="mappedUserData.length"
      />
      <AvatarCountBadgeWithModal
        :size="'30px'"
        :fontSize="'14px'"
        :count="userCount"
        :userData="dashboardUsers"
        :placement="'right'"
        :modalDirection="'top'"
        v-if="userCount > 0"
      />
    </DashboardUserWrapperStyled>
  </DashboardFooterStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import slice from 'lodash/slice'
import { LoadingDots } from '@common/components'
import resizeMixin from '@/mixins/resize'
import { useStore } from '@/stores/userSettings'

import DashboardToolbox from '@/components/Atomic/Molecules/DashboardToolbox.vue'
import AvatarWithSimplePopOver from '@/components/Atomic/Molecules/AvatarWithSimplePopOver.vue'
import AvatarCountBadgeWithModal from '@/components/Atomic/Molecules/AvatarCountBadgeWithModal.vue'

import DASHBOARD_SHARES_QUERY from '#/graphql/operations/dashboard/share/dashboardShares.gql'
import ORGANIZATIONS_QUERY from '#/graphql/organizations/organizations.gql'

const DashboardFooterStyled = styled('div')`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.mainBG};
  padding: 0.5rem;
  &.scrolled {
    background-color: ${p => chroma(p.theme.colors.solidBG).css()};
    border-top: 1px solid
      ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.16).css())};
  }
`
const DashboardUserWrapperStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.atomic.toolsMenuBG};
  padding: 0.5rem;
  border-radius: 8px;
  height: 28px;
  box-shadow: 0px 3px 6px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.16).css())};
  align-items: center;
  > div {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem;
    align-self: center;
  }
`
export default {
  inject: ['permissions'],
  mixins: [resizeMixin],
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      require: true,
      default: false,
    },
    isArchonorTalpaProduct: {
      type: Boolean,
      require: true,
      default: false,
    },
    userId: {
      type: String,
      required: true,
    },
    hasScrolled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    DashboardFooterStyled,
    DashboardUserWrapperStyled,
    AvatarWithSimplePopOver,
    AvatarCountBadgeWithModal,
    DashboardToolbox,
    LoadingDots,
  },
  data() {
    return {
      isMobile: false,
      dashboardUsersData: {},
    }
  },
  computed: {
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
    canEditDashboard() {
      return get(this.permissions, 'write', []).find(p => p.name === 'dashboard_write') && get(this.dashboard, 'isEditable', false)
    },
    canShareDashboard() {
      return this.isOwner && this.hasDashboardShareFeature
    },
    hasDashboardShareFeature() {
      if (!this.isArchonorTalpaProduct) {
        if (this.organizations) {
          const orgsWithDashboardSharing = this.organizations.filter(org => {
            const userMemebership = get(org, 'memberships', []).find(membership => membership.userId === this.userId)
            const isAdmin = get(userMemebership, 'role', '') === 'ADMIN'
            const hasDashboardSharingFeature = Boolean(get(org, 'hasDashboardSharingFeature', false))
            const hasDashboardSharingForAll = Boolean(get(org, 'dashboardSharingEnabledForAllUsers', false))
            const isAdminWithDashboardSharingFeature = isAdmin && hasDashboardSharingFeature
            const isNonAdminWithDashboardSharingForAll = !isAdmin && hasDashboardSharingFeature && hasDashboardSharingForAll
            return isAdminWithDashboardSharingFeature || isNonAdminWithDashboardSharingForAll
          })
          return orgsWithDashboardSharing.length > 0
        }
        return false
      }
      return this.isArchonorTalpaProduct
    },
    dashboardUsers() {
      const dashboard = { ...this.dashboardUsersData }
      const owner = get(dashboard, 'owner', {})
      owner.isOwner = true

      const sharedList = get(dashboard, 'shares', []).map(s => {
        return {
          shareId: s.id,
          ...s.sharedWithUser,
        }
      })
      const mappedList = [...sharedList, owner]
      const sortedList = sortBy(mappedList, ({ isOwner }) => (isOwner ? 0 : 1))
      return sortedList
    },
    mappedUserData() {
      let filteredUsers = this.dashboardUsers
      if (!this.isMobile) {
        filteredUsers = this.dashboardUsers && this.dashboardUsers.length > 4 ? slice(this.dashboardUsers, 0, 4) : this.dashboardUsers
      } else {
        filteredUsers = this.dashboardUsers && this.dashboardUsers.length > 1 ? slice(this.dashboardUsers, 0, 1) : this.dashboardUsers
      }
      return filteredUsers
    },
    userCount() {
      const count = this.dashboardUsers.length - this.mappedUserData.length
      return count
    },
    dashboardId() {
      return get(this.dashboard, 'id', '')
    },
    widgets() {
      return get(this.dashboard, 'gridItems', [])
    },
  },
  methods: {
    editMode(value) {
      this.$emit('editModeToggle', value)
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        this.isMobile = size < 768 && size >= 96
      })
    },
  },
  apollo: {
    organizations: {
      query: ORGANIZATIONS_QUERY,
      variables() {
        return {
          where: {
            OR: [
              {
                memberships_some: {
                  userId: this.userId,
                  role: 'ADMIN',
                },
                hasDashboardSharingFeature: true,
              },
              {
                memberships_some: {
                  userId: this.userId,
                  role: 'USER',
                },
                nonAdminUsersCanSeeOtherUsers: true,
                hasDashboardSharingFeature: true,
              },
            ],
          },
        }
      },
    },
    dashboardUsersData: {
      query: DASHBOARD_SHARES_QUERY,
      variables() {
        return {
          where: {
            id: this.dashboardId,
          },
        }
      },
      skip() {
        return !this.dashboardId
      },
      update: data => data.dashboard,
    },
  },
}
</script>
