<template>
  <WrapperStyled :placement="placement" :modalDirection="modalDirection">
    <div @click="toggleMemberModal">
      <AvatarCountBadge :size="size" :fontSize="fontSize" :count="count" class="avatarCount" />
    </div>
    <transition name="slide-fade">
      <Modal class="modal" v-if="modalExpanded">
        <template v-slot:header>
          <div class="uppercase">{{ headerTitle }}</div>
          <XIcon @click="toggleMemberModal" />
        </template>
        <template v-slot:main>
          <UserWrapperStyled v-for="data in userData" :key="data.id">
            <AvatarBadge :size="'30px'" :fontSize="'14px'" :userData="data" :isOwner="data.isOwner" />
            <UserStyled id="sensitive">
              <div class="userName">{{ data.username }}</div>
              <div class="sub-text">{{ data.email }}</div>
            </UserStyled>
            <OwnerBadge v-if="data.isOwner" />
          </UserWrapperStyled>
        </template>
      </Modal>
    </transition>
  </WrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import AvatarCountBadge from '@/components/Atomic/Atoms/AvatarCountBadge'
import Modal from '@/components/Atomic/Atoms/Modal'
import AvatarBadge from '@/components/Atomic/Atoms/AvatarBadge'
import OwnerBadge from '@/components/Atomic/Atoms/OwnerBadge'

import { XIcon } from 'vue-feather-icons'

const WrapperStyled = styled('div')`
  cursor: pointer;
  display: flex;
  .uppercase {
    text-transform: uppercase;
  }
  .avatarCount {
    &:hover {
      transform: translateY(1px);
    }
    &:active {
      transform: translateY(1px);
    }
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.3, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }
  .modal {
    width: 260px;
    position: absolute;
    ${({ placement }) => (placement === 'left' ? placement && 'left: 0' : placement && 'right: 0')};
    ${({ modalDirection }) => (modalDirection === 'top' ? modalDirection && 'bottom: 4rem' : modalDirection && 'top: 3rem')};
    display: flex;
    flex-direction: column;
    > header {
      display: flex;
      justify-content: space-between;
      .feather {
        color: ${({ theme }) => theme.colors.primary};
        &:hover {
          color: ${({ theme }) => theme.colors.primaryActive};
        }
      }
    }
    > main {
      display: block;
      height: 165px;
      padding: 0.5rem;
    }
  }  
  }
`
const UserStyled = styled('div')`
  .userName {
    font-size: 14px;
  }
  .sub-text {
    font-size: 10px;
  }
`
const UserWrapperStyled = styled('div')`
  display: grid;
  grid-template-columns: 2rem auto auto;
  align-items: center;
  gap: 0.5rem;
  padding-top: 0.5rem;
`
export default {
  inject: ['theme'],
  props: {
    size: {
      type: String,
      require: false,
      default: '25px',
    },
    fontSize: {
      type: String,
      require: false,
      default: '12px',
    },
    count: {
      type: Number,
      require: true,
    },
    userData: {
      type: Array,
      require: true,
    },
    modalDirection: {
      type: String,
      require: false,
      default: 'top',
    },
    placement: {
      type: String,
      require: false,
      default: 'left',
    },
  },
  data() {
    return {
      modalExpanded: false,
    }
  },
  components: {
    AvatarCountBadge,
    WrapperStyled,
    Modal,
    XIcon,
    AvatarBadge,
    UserStyled,
    UserWrapperStyled,
    OwnerBadge,
  },
  computed: {
    headerTitle() {
      return this.$tc('member', 2)
    },
  },
  methods: {
    toggleMemberModal() {
      this.modalExpanded = !this.modalExpanded
    },
  },
}
</script>
