<template>
  <SingleAssetSelectorStyled>
    <ButtonStyled :isLeft="true" @click="prevAsset" :disabled="prevDisabled">
      <ChevronLeftIcon />
    </ButtonStyled>
    <Multiselect
      :value="singleSelectedAsset"
      :multiple="false"
      :options="dashboardAssets"
      :showLabels="false"
      :placeholder="$tc('asset', 2)"
      trackBy="id"
      label="name"
      class="semi-darker"
      @select="updateSingleSelectedAsset"
    />
    <ButtonStyled @click="nextAsset" :disabled="nextDisabled">
      <ChevronRightIcon />
    </ButtonStyled>
  </SingleAssetSelectorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'
import chroma from 'chroma-js'
import { flexCenter, buttonReset } from '@styles/mixins'
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'
import { useAssetStore } from '@/stores/assets'

const SingleAssetSelectorStyled = styled('div')`
  ${flexCenter}
  align-items: stretch;
  width: 360px;
  max-width: calc(100vw - 52px);

  .multiselect {
    max-height: 40px;
    min-height: 0;
    border-bottom: 1px solid ${({ theme }) => chroma(theme.colors.solidBGContrastColor).alpha(0.8).css()};
    &:hover {
      border-bottom: 1px solid ${({ theme }) => chroma(theme.colors.primary).css()};
    }
    .multiselect__option--highlight {
      background: ${p => p.theme.colors.atomic.hover};
      color: ${p => (p.theme.isDark ? p.theme.colors.white : p.theme.colors.black)};
    }
    & .multiselect__option--selected {
      background: ${p => p.theme.colors.primary} !important;
      color: white !important;
    }
  }

  @media (max-width: 698px) {
    font-size: 10px;
    .multiselect__tags {
      padding: 0px;
    }
  }
`

const ButtonStyled = styled('button')`
  ${buttonReset}
  ${flexCenter}
  color: ${p => p.theme.colors.primary};
  background: ${p => chroma(p.theme.colors.darkGrey).alpha(0.3).css()};
  opacity: ${p => (p.disabled ? 0.5 : 1)};
  ${p => {
    if (p.isLeft) {
      return `
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
        margin-right: 2px;
      `
    }
    return `
      border-top-right-radius: .5rem;
      border-bottom-right-radius: .5rem;
      margin-left: 2px;
    `
  }}
`

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  components: {
    SingleAssetSelectorStyled,
    Multiselect,
    ChevronLeftIcon,
    ChevronRightIcon,
    ButtonStyled,
  },
  computed: {
    singleSelectedAsset() {
      return this.assetStore.singleSelectedAsset()
    },
    dashboardAssets() {
      return this.assetStore.getDashboardAssets()
    },
    prevDisabled() {
      return this.dashboardAssets.length < 2
    },
    nextDisabled() {
      return this.dashboardAssets.length < 2
    },
  },
  methods: {
    prevAsset() {
      let idx = this.dashboardAssets.findIndex(da => da.id === this.singleSelectedAsset.id) - 1
      if (idx < 0) {
        idx = this.dashboardAssets.length - 1
      }
      this.updateSingleSelectedAsset(this.dashboardAssets[idx])
    },
    nextAsset() {
      let idx = this.dashboardAssets.findIndex(da => da.id === this.singleSelectedAsset.id) + 1
      if (idx >= this.dashboardAssets.length) {
        idx = 0
      }
      this.updateSingleSelectedAsset(this.dashboardAssets[idx])
    },
    updateSingleSelectedAsset(asset) {
      if (asset && asset.id) {
        this.$router.push({
          query: {
            // don't overwrite other query params
            ...this.$route.query,
            assetID: asset.id,
          },
        })
      }
    },
  },
}
</script>
