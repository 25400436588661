<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <DashboardStyled v-else :picture="dashboardBackground" :showInitialAdd="showInitialAdd">
    <InitialAddStyled v-if="showInitialAdd">
      <div>{{ $t('messages.emptyDashboard') }}</div>
      <div v-if="canAddWidget">{{ $t('messages.emptyDashboardAdd') }}</div>
    </InitialAddStyled>
    <MainStyled v-else>
      <Subheader :hasScrolled="hasScrolled" />
      <TalpaLoaderWrapper v-if="isLoading" />
      <GridLayout
        :layout="layout"
        :col-num="12"
        :row-height="3 * 16"
        :is-draggable="true"
        :is-resizable="true"
        :is-mirrored="false"
        :margin="[8, 8]"
        :use-css-transforms="true"
        :responsive="true"
        :autoSize="true"
        :verticalCompact="true"
        :breakpoints="breakpoints"
        :cols="breakpointCols"
        @layout-updated="layoutUpdated"
        v-else
      >
        <GridItem
          v-for="gridItem in layout"
          :key="gridItem.id"
          :x="gridItem.x"
          :y="gridItem.y"
          :w="gridItem.w"
          :h="gridItem.h"
          :i="gridItem.i"
          :is-draggable="isEditMode"
          :is-resizable="isEditMode"
          :dragAllowFrom="'.widget-header'"
          @resize="gridItemResize(gridItem.id, $event)"
          @move="gridItemMove(gridItem.id, $event)"
          @resized="gridItemResized(gridItem.id, $event)"
          @moved="gridItemMoved(gridItem.id, $event)"
        >
          <Widget
            :isEditMode="isEditMode"
            :widget="gridItem.widget"
            :gridItemID="gridItem.id"
            :dashboardType="dashboard.type"
            :ref="gridItem.id"
            @showWidgetSettings="showWidgetSettings(gridItem)"
            @deleteGridItem="deleteGridItem(gridItem)"
            @updateTitle="updateWidgetTitle($event, gridItem.id)"
          />
        </GridItem>
      </GridLayout>
    </MainStyled>
    <DashboardFooter
      v-if="canAddWidget"
      class="footer"
      @editModeToggle="editMode"
      :hasScrolled="hasScrolled"
      :dashboard="dashboard"
      :isOwner="isOwner"
      :isArchonorTalpaProduct="isArchonorTalpaProduct"
      :userId="userId"
    />
  </DashboardStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { GridLayout, GridItem } from 'vue-grid-layout'
import { flexColumns } from '@styles/mixins'
import { TalpaLoaderWrapper } from '@common/components'

import { getUserIdFromToken, getRolesFromToken } from '@common/utils'

import Subheader from './Main/Subheader'
import Widget from '@/components/Widgets/Widget'
import DashboardFooter from '@/components/Atomic/Organisms/Dashboards/DashboardFooter.vue'

import { useAssetStore } from '@/stores/assets'

import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'
import MY_ASSETS_SITE_QUERY from '#/graphql/operations/assets/minimals/myAssetsSite.gql'
import OEMS_QUERY from '#/graphql/operations/assets/minimals/oems.gql'
import DASHBOARD_UPDATE_GRID_MUTATION from '#/graphql/operations/dashboard/gridItem/updateDashboardGridItems.gql'
import DELETE_GRID_ITEM_MUTATION from '#/graphql/operations/dashboard/gridItem/delete.gql'
import DASHBOARD_FULL_QUERY from '#/graphql/operations/dashboard/dashboardList.gql'
import MY_SUBSIDIARIES_WITH_ASSETS_QUERY from '#/graphql/operations/subsidiaries/mySubsidiariesWithAssetsQuery.gql'

const DashboardStyled = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 4rem;

  grid-template-areas: ${({ showInitialAdd }) =>
    showInitialAdd
      ? `
    'info'
    'footer';
  `
      : `
    'main'
    'footer';`};
  @media (min-width: 768px) {
    ${p => (p.picture && p.backgroundEnabled ? 'background: url(' + p.picture + ') center center/cover no-repeat fixed' : '')}
  }
  .vue-grid-layout {
    position: relative;
    width: 100%;
    max-width: 100%;
    .vue-grid-item {
      box-sizing: border-box;
      touch-action: none;
    }
    .vue-resizable-handle {
      color: white;
      z-index: 100;
      ${p => (p.theme.isDark ? 'filter: invert(100%)' : '')};
    }
  }
`

const InitialAddStyled = styled('div')`
  grid-area: info;
  ${flexColumns}
  align-items: center;
  padding: 2rem;
  > div {
    margin-bottom: 2rem;
  }
`
const MainStyled = styled('div')`
  grid-area: main;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`
export default {
  inject: ['permissions'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    hasScrolled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DashboardStyled,
    Subheader,
    GridLayout,
    GridItem,
    InitialAddStyled,
    Widget,
    DashboardFooter,
    TalpaLoaderWrapper,
    MainStyled,
  },
  data() {
    return {
      isEditMode: false,
      isAddingWidget: false,
      isMutating: false,
      layout: [],
      isDisabled: true,
      dashboard: {
        gridItems: [],
      },
      breakpoints: {
        small: 0,
        large: 768,
      },
      breakpointCols: {
        small: 2,
        large: 12,
      },
      mySubsidiaries: [],
      assetsSiteMap: new Map(),
      oemsMap: new Map(),
      assets: [],
      isLoading: false,
      queriesLoaded: {
        dashboard: false,
        assets: false,
        myAssetsSite: false,
        oems: false,
        mySubsidiaries: false,
      },
    }
  },
  computed: {
    showInitialAdd() {
      return get(this.dashboard, 'gridItems.length', 0) === 0
    },
    dashboardBackground() {
      return get(this.dashboard, 'picture.url', null)
    },
    canAddWidget() {
      return get(this.permissions, 'write', []).find(p => p.name === 'dashboard_write') && get(this.dashboard, 'isEditable', false)
    },
    isOwner() {
      return get(this.dashboard, 'ownerUserId', '') === this.$keycloak.subject
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    isArchonorTalpaProduct() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    userId() {
      return getUserIdFromToken(this.$keycloak.token)
    },
    availableAssets() {
      return this.assetStore.allAssets.length ? this.assetStore.allAssets : this.assets
    },
    dashboardAssets() {
      const assetFilter = this.dashboard?.assetFilter
      const assetGroups = this.dashboard?.assetGroups
      let assets = []
      this.showLoader(true)
      if (assetFilter === 'MANUAL' && this.dashboard?.assets?.length > 0) {
        assets = this.assetsWithUnknownCategories.filter(asset =>
          this.dashboard.assets.some(dashboardAsset => dashboardAsset?.id === asset?.id),
        )
      } else if (assetFilter && assetFilter !== 'NONE' && assetGroups?.length) {
        assets = this.getSelectedAssetsByFilter(assetGroups)
      } else {
        // to handle backward compatibility of NONE
        assets = this.assetsWithUnknownCategories
      }
      this.showLoader(false)
      return assets
    },
    assetsWithUnknownCategories() {
      const assetsWithUnknownDataFromStore = this.assetStore.getAssetsWithUnknownData()
      if (assetsWithUnknownDataFromStore.length) {
        return assetsWithUnknownDataFromStore
      }

      const availableAssets = this.availableAssets
      const subsidiariesMap = new Map()

      // Pre-compute subsidiaries map
      this.mySubsidiaries?.forEach(sub => {
        sub.assets.forEach(subAsset => {
          if (!subsidiariesMap.has(subAsset.id)) {
            subsidiariesMap.set(subAsset.id, [])
          }
          subsidiariesMap.get(subAsset.id).push({ id: sub.id, name: sub.name })
        })
      })

      // Pre-compute values for unknown entities
      const unknownSite = { id: 'unknown', name: this.$tc('unknownSite', 1), __typename: 'AssetSite' }
      const unknownModel = { id: 'unknown', name: this.$t('unknownModel'), __typename: 'MachineModel' }
      const unknownOEM = { id: 'unknown', name: this.$t('unknownOEM'), alias: this.$t('unknownOEM'), __typename: 'OEM' }
      const unknownType = { id: 'unknown', name: this.$t('unknownType'), __typename: 'MachineType' }
      const unknownSubsidiary = [{ id: 'unknown', name: this.$t('unknownUsergroup'), __typename: 'Subsidiary' }]

      const mappedAssets = availableAssets.map(({ model, type, ...asset }) => {
        const oem = this.oemsMap.get(asset.manufacturerCuid) || unknownOEM
        const site = this.assetsSiteMap.get(asset.id)
          ? { ...this.assetsSiteMap.get(asset.id), id: this.assetsSiteMap.get(asset.id).siteId }
          : unknownSite
        const subsidiaries = subsidiariesMap.get(asset.id) || unknownSubsidiary

        return {
          subsidiary: subsidiaries,
          site,
          model: model || unknownModel,
          oem,
          type: type || unknownType,
          ...asset,
        }
      })

      this.assetStore.setAssetsWithUnknownData(mappedAssets)
      return mappedAssets
    },

    isSingleAssetView() {
      const type = this.dashboard?.type ?? 'NONE'
      return type === 'SINGLE_ASSET' || type === 'TEMPLATE_SINGLE_ASSET'
    },
    allQueriesLoaded() {
      // Check if all values in queriesLoaded are true
      return Object.values(this.queriesLoaded).every(Boolean)
    },
  },
  watch: {
    dashboard(val) {
      const gridItems = get(val, 'gridItems', [])
      this.layout = gridItems.map(item => ({
        ...item,
        i: item.id,
      }))
    },
    $route: {
      handler() {
        if (this.isSingleAssetView) {
          this.setAssetToSingleViewDashboard()
        }
      },
      immediate: true,
    },
    isSingleAssetView: {
      handler(isSingleAssetView) {
        if (isSingleAssetView) {
          this.setAssetToSingleViewDashboard()
        }
      },
      immediate: true,
    },
    allQueriesLoaded(newVal) {
      if (newVal) {
        this.handleAllQueriesLoaded()
      }
    },
  },
  methods: {
    setAssetToSingleViewDashboard() {
      const assetID = get(this.$route, 'query.assetID', null)
      if (assetID) {
        this.setSingleViewSelectedAssetID(assetID)
      } else {
        const firstAssetID = get(this.dashboardAssets, [0, 'id'], null)
        if (firstAssetID) {
          this.setSingleViewSelectedAssetID(firstAssetID)
        }
      }
    },
    handleAllQueriesLoaded() {
      this.setDashboardAssetsToStore()
    },
    showLoader(value) {
      this.isLoading = value
    },
    setDashboardAssetsToStore() {
      this.assetStore.dashboardAssets = this.dashboardAssets
      if (this.isSingleAssetView && this.dashboardAssets?.length > 0) {
        if (!this.assetStore.singleViewSelectedAssetID) {
          const found = this.dashboardAssets?.find(asset => asset === get(this.assetStore.assetsSelected, '[0].id', ''))
          if (!found) {
            const asset = this.dashboardAssets[0].id ?? []
            this.setSingleViewSelectedAssetID(asset)
          }
        }
      }
    },
    setSingleViewSelectedAssetID(assetID) {
      const dashboard = {
        id: this.id,
        assetId: assetID,
      }
      this.assetStore.setSingleViewDashboardSetting(dashboard)
      this.setSingleViewSelectedAssetIDToStore()
    },
    setSingleViewSelectedAssetIDToStore() {
      const currentDashboardSettings = this.assetStore.singleViewDashboardSetting.find(({ id }) => id === this.id)
      let selectedAssetId = null
      if (currentDashboardSettings) {
        selectedAssetId = currentDashboardSettings.assetId
      }
      this.assetStore.setSingleViewSelectedAssetID(selectedAssetId)
    },
    assetsGroupedByFilters(data) {
      const assets = this.assetsWithUnknownCategories
      if (!assets || !this.dashboard?.assetFilter) {
        return []
      }

      const filterType = this.dashboard.assetFilter
      const dataId = data.id
      let filteredAssets = []

      switch (filterType) {
        case 'SITE':
          filteredAssets = this.getFilteredAssetsByKey(assets, 'site', dataId)
          break
        case 'OEM':
          filteredAssets = this.getFilteredAssetsByKey(assets, 'oem', dataId)
          break
        case 'MODEL':
          filteredAssets = this.getFilteredAssetsByKey(assets, 'model', dataId)
          break
        case 'ASSET_TYPE':
          filteredAssets = this.getFilteredAssetsByKey(assets, 'type', dataId)
          break
        case 'USER_GROUP':
          filteredAssets = assets.filter(asset => asset?.subsidiary.some(sub => sub.id === dataId))
          break
      }

      return filteredAssets
    },

    getFilteredAssetsByKey(assets, key, dataId) {
      return assets.filter(asset => asset[key]?.id === dataId)
    },
    getSelectedAssetsByFilter(groups) {
      const groupedAssets =
        groups?.flatMap(group => {
          const id = group.groupType === 'MachineType' || group.groupType === 'MachineModel' ? parseInt(group.groupId) : group.groupId
          return this.assetsGroupedByFilters({ id, name: group.groupName, __typename: group.groupType })
        }) || []

      // Remove duplicates
      const uniqueGroupedAssets = new Set(groupedAssets)

      return [...uniqueGroupedAssets]
    },
    editMode(value) {
      this.isEditMode = value
    },
    gridItemResize() {},
    gridItemMove() {},
    gridItemResized(gridItemID) {
      const item = this.$refs[gridItemID][0]
      if (item && typeof item.gridItemResized === 'function') {
        item.gridItemResized()
      }
    },
    gridItemMoved(gridItemID) {
      const item = this.$refs[gridItemID][0]
      if (item && typeof item.gridItemMoved === 'function') {
        item.gridItemMoved()
      }
    },
    async layoutUpdated(layout) {
      const variables = {
        where: {
          id: this.id,
        },
        data: {
          gridItems: {
            update: layout.map(gridItem => ({
              where: {
                id: gridItem.id,
              },
              data: {
                w: gridItem.w,
                h: gridItem.h,
                x: gridItem.x,
                y: gridItem.y,
              },
            })),
          },
        },
      }
      await this.$apollo.mutate({
        mutation: DASHBOARD_UPDATE_GRID_MUTATION,
        variables,
      })
    },

    async deleteGridItem(item) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_GRID_ITEM_MUTATION,
          variables: {
            where: {
              id: item.id,
            },
          },
          update: (store, { data: { deleteGridItem } }) => {
            let data = store.readQuery({
              query: DASHBOARD_FULL_QUERY,
              variables: {
                id: this.id,
              },
            })
            data.dashboard.gridItems = data.dashboard.gridItems.filter(d => d.id !== deleteGridItem.id)
            store.writeQuery({
              query: DASHBOARD_FULL_QUERY,
              variables: {
                id: this.id,
              },
              data,
            })
            this.layout = data.dashboard.gridItems.map(item => ({
              ...item,
              i: item.id,
            }))
          },
        })
      } catch (err) {
        console.error(err) // eslint-disable-line no-console
      }
    },
  },
  apollo: {
    dashboard: {
      query: DASHBOARD_FULL_QUERY,
      variables() {
        return {
          id: this.id,
        }
      },
      result({ data }) {
        if (data.dashboard === null) {
          this.$router.push({
            name: 'dashboards',
          })
        }
        const type = data?.dashboard?.type ?? 'NONE'
        this.assetStore.isSingleAssetView = type === 'SINGLE_ASSET' || type === 'TEMPLATE_SINGLE_ASSET'
        this.queriesLoaded.dashboard = true
      },
    },
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
      result({ data }) {
        if (data.assets.length) {
          this.assetStore.allAssets = data?.assets ?? []
        }
        this.queriesLoaded.assets = true
      },
    },
    myAssetsSite: {
      query: MY_ASSETS_SITE_QUERY,
      result({ data }) {
        this.assetsSiteMap = new Map(data.myAssetsSite.map(asset => [asset.assetId, asset]))
        this.queriesLoaded.myAssetsSite = true
      },
    },
    oems: {
      query: OEMS_QUERY,
      result({ data }) {
        this.oemsMap = new Map(data.oems.map(oem => [oem.id, oem]))
        this.queriesLoaded.oems = true
      },
    },
    mySubsidiaries: {
      query: MY_SUBSIDIARIES_WITH_ASSETS_QUERY,
      result() {
        this.queriesLoaded.mySubsidiaries = true
      },
    },
  },
}
</script>
