var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WidgetErrorStyled', [_vm.widget && _vm.widget.type ? _c('i18n', {
    attrs: {
      "path": "messages.errorLoadingWidgetType"
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.widget.type) + " ")];
      },
      proxy: true
    }], null, false, 2614362802)
  }) : _vm._e(), [_vm._v(" " + _vm._s(_vm.$t('messages.errorLoadingWidget')) + " ")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }